body {
	margin: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.navbar {
	position: "fixed";
}

@keyframes App-pink-spin {
	to {
		transform: rotate(380deg);
	}
}

@font-face {
	font-family: "CeraCompactPro";
	src: local("Cera-Pro-Regular"),
		url(./fonts/Cera-Pro-Regular.woff) format("woff");
}
